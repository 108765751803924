export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="section-title">
                <h2>Contact Info</h2>
              </div>
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <div className="contact-item">
                    <p>
                      <i className="fa fa-map-marker"></i> Address
                    </p>
                    <a
                      href="https://www.google.com/maps/search/?api=1&query=Tutukaka+Coast,+Whangārei,+Northland,+New+Zealand"
                      target="_blank"
                      rel="noreferrer"
                      className="text-white link-content"
                    >
                      <span>
                        Tutukaka Coast, Whangārei, Northland, New Zealand &nbsp;
                        <i className="fa fa-external-link"></i>
                      </span>
                    </a>
                  </div>
                  <div className="contact-item">
                    <p>
                      <span>
                        <i className="fa fa-phone"></i> Phone
                      </span>{" "}
                    </p>
                    <a
                      href="tel:+64272228520"
                      className="text-white link-content"
                    >
                      <span>
                        +64272228520 &nbsp;
                        <i className="fa fa-external-link"></i>
                      </span>
                    </a>
                  </div>
                  <div className="contact-item">
                    <p>
                      <span>
                        <i className="fa fa-envelope-o"></i> Email
                      </span>
                    </p>
                    <a
                      href="mailto:info@journeymobilesaunas.co.nz"
                      className="text-white link-content"
                    >
                      <span>
                        info@journeymobilesaunas.co.nz &nbsp;
                        <i className="fa fa-external-link"></i>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <div className="contact-item">
                    <img
                      src="img/journey-mobile-saunas-logo.svg"
                      alt="Journey Mobile Saunas"
                      className="footer-logo"
                    />
                    <h3 className="footer-text">
                      Start Your Journey to Wellness Today
                    </h3>
                    <div className="footer-btn">
                      <a
                        href="https://www.picktime.com/journeybookings"
                        target="_blank"
                        rel="noreferrer"
                        className="btn-custom btn-lg"
                      >
                        Book Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a
                      href={props.data ? props.data.facebook : "/"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={props.data ? props.data.instagram : "/"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; {new Date().getFullYear()} Journey Mobile Saunas. Developed
            by{" "}
            <a
              href="http://www.jerommiole.com"
              target="_blank"
              rel="noreferrer"
            >
              Jerom Miole
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
