import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img
              src="img/about.jpg"
              className="img-responsive"
              alt="About Us"
              width="100%"
              height="auto"
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>Journey Mobile Saunas: Where Ocean Meets Wellness</p>
              <p>
                Journey Mobile Saunas was born from a passion for health and a
                desire to share the transformative power of combining sauna
                bathing with the natural beauty of Whangarei's beaches.
              </p>
              <p>
                Our story began with a personal journey towards healing. After
                experiencing the profound benefits of saunas firsthand, we were
                determined to bring this ancient practice to others. We saw a
                gap in the market, with only one existing sauna option that
                wasn't quite hitting the spot.
              </p>
              <p>
                Journey Mobile Saunas is more than just a sauna; it's an
                experience unlike any other. We offer an authentic, wood-fired
                cedar barrel sauna that can comfortably accommodate up to 8
                people. Imagine unwinding in the warmth of the sauna, surrounded
                by the natural beauty of the Northland beaches. Breathe in the
                fresh ocean air and allow the heat to melt away stress and
                tension.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
