import { Image } from "./image";
import React, { useState } from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "70%",
    maxHeight: "70%",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

export const Gallery = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
          <p>
            Discover the warmth and serenity of our wood-fired saunas. Explore
            our gallery to envision your next wellness escape.
          </p>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                    onClick={() => openModal(d.largeImage)}
                  >
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                      width="100%"
                      height="auto"
                    />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        style={customStyles}
      >
        <div
          style={{ position: "absolute", top: 4, right: 4 }}
          onClick={closeModal}
        >
          <i className="fa fa-times fa-lg"></i>
        </div>
        <img
          src={currentImage}
          alt="Modal"
          style={{ width: "100%", maxHeight: "550px" }}
        />
      </Modal>
    </div>
  );
};
