import React from "react";

export const Image = ({ title, smallImage }) => {
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        <div className="hover-text">
          <p>{title}</p>
        </div>
        <img src={smallImage} className="img-responsive" alt={title} />{" "}
      </div>
    </div>
  );
};
